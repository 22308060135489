h1 {
    font-size: 25px;
}

h2 {
    font-size: 15px;
}

@media screen and (max-width: 1024px) {
    h1 {
        font-size: 25px;
    }

    h2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 768px) {
    h1 {
        font-size: 25px;
    }

    h2 {
        font-size: 25px;
    }
}

@media screen and (max-width: 425px) {
    h1 {
        font-size: 18px;
    }

    h2 {
        font-size: 14px;
    }
}

@media screen and (max-width: 375px) {
    h1 {
        font-size: 15px;
    }

    h2 {
        font-size: 10px;
    }
}

@media screen and (max-width: 320px) {
    h1 {
        font-size: 15px;
    }

    h2 {
        font-size: 10px;
    }
}