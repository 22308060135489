.main-nav {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 3.5vw;
    padding-right: 3.5vw;
    background-color: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    box-shadow: 0px 1px 3px 3px rgba(0, 0, 0, 0.2);
}

.main-nav a {
    text-decoration: none;
    cursor: pointer;
}

.brand img {
    width: 60px;
}

.nav {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
}

.nav a, .contact a {
    display: inline-flex;
    position: relative;
    font-weight: 500;
    font-size: 18px;
}

.nav a::before, .contact a::before {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.nav a:hover::before, .contact a:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.icon {
    display: none; 
}

@media screen and (max-width: 600px) {
    .icon {
        display: inline-flex;
        cursor: pointer;
    }

    .nav {
        display: none;
    }

    .contact {
        display: none;
    }

    .brand img {
        width: 28.5px;
    }
}