.intro {
    min-height: 100vh;
    font-family: 'Raleway', sans-serif;
}

.landing {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: 12.5vw;
    padding-right: 12.5vw;
}

.intro-title h1 {
    font-size: 10vw;
     /* font-size: 150px; */
    font-weight: 700;
} 

.intro-subtitle h2 {
    font-size: 3vw;
    /* font-size: 30px; */
    font-weight: 400;
}