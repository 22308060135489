.about {
    min-height: 100vh;
    width: 100vw;
    font-family: 'Raleway', sans-serif;
}
 
.about .about-intro {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 12.5vw;
    padding-left: 12.5vw;
}

.about .about-intro h1 {
    font-weight: 700;
    /* font-size: 200px; */
    font-size: 20vw;
}

.about .summary {
    margin-top: 10vh;
    margin-bottom: 10vh;
    padding-right: 12.5vw;
    padding-left: 12.5vw;
    min-height: 100vh;
}

.about .summary p {
    font-size: 22px;
    text-align: center;
}

.about .summary .profile-pic {
    height: 100%;
    display: flex;
    justify-content: center;
}

.about .summary .profile-pic img {
    max-width: 80%;
    object-fit: contain;
    border-radius: 30px;
    transition: max-width 0.5s ease;
}

.about .summary .profile-pic img:hover {
    max-width: 90%;
    transition: max-width 0.5s ease;
}

.about .summary .resume-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.about .summary .resume-container .resume-button {
    color: #000;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 7px;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 50px;
    padding-right: 50px;
    outline: none;
    transition: background-color 0.5s ease, font-size 0.5s ease;
}

.about .summary .resume-container .resume-button:hover {
    color: #fff;
    background-color: #ffd42d;
    font-size: 25px;
    transition: background-color 0.5s ease, font-size 0.5s ease;
}

.about .history {
    margin-top: 10vh;
    margin-bottom: 10vh; 
    padding-bottom: 50px;
    padding-right: 12.5vw;
    padding-left: 12.5vw;
}

.about .history .history-title {
    margin-bottom: 50px;
    font-size: 55px;
    text-align: center;
}

@media screen and (max-width: 1024px) {
    /* .about .about-intro h1 {
        font-size: 200px;
    } */

    .about .summary p {
        font-size: 20px;
    }

    .about .history .history-title {
        font-size: 50px;
    }
}

@media screen and (max-width: 768px) {
    /* .about .about-intro h1 {
        font-size: 150px;
    } */

    .about .summary p {
        font-size: 15px;
    }

    .about .history .history-title {
        font-size: 40px;
    }
}

@media screen and (max-width: 425px) {
    /* .about .about-intro h1 {
        font-size: 80px;
    } */

    .about .summary p {
        font-size: 20px;
    }

    .about .history .history-title {
        font-size: 35px;
    }
}

@media screen and (max-width: 375px) {
    /* .about .about-intro h1 {
        font-size: 75px;
    } */

    .about .summary p {
        font-size: 15px;
    }

    .about .history .history-title {
        font-size: 30px;
    }
}

@media screen and (max-width: 320px) {
    /* .about .about-intro h1 {
        font-size: 60px;
    } */

    .about .summary p {
        font-size: 15px;
    }

    .about .history .history-title {
        font-size: 25px;
    }
}