@keyframes popup-regular {
    from {top: 200%;}
    to {top:  50%;}
}

@keyframes popup-small {
    from {left: 150%;}
    to {left: 63%;}
}

.overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 99;
    top: 0;
    left: 0;
}

.popup {
    position: fixed;
    z-index: 100;
    width: 97vw;
    height: 97vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    font-family: 'Raleway', sans-serif;
    border-radius: 10px;
    animation-name: popup-regular;
    animation-duration: 0.75s;
    animation-timing-function: ease;
}

.popup-content-regular {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    color: #000;
}

.popup-content-small {
    display: none;
}

.close {
    cursor: pointer;
    font-size: 35px;
}

.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.contact-info a {
    font-size: 55px;
    position: relative;
}

.contact-info a::before {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.contact-info a:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.social {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.social h3 {
    position: relative;
}

.social h3::after {
    content: 'ly Awkward';
    display: none;
}

.social h3:hover::after {
    display: inline-flex;
}

@media screen and (max-width: 600px) {
    .popup {
        width: 75vw;
        height: 100vh;
        border-radius: 0px;
        top: 50%;
        left: 63%;
        overflow-x: hidden;
        animation-name: popup-small;
    }

    .popup-content-regular {
        display: none;
    }

    .popup-content-small {
        height: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .popup-content-small #small-nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50%;
    }

    .popup-content-small #small-nav a {
        font-size: 30px;
    }

    .popup-content-small .contact-info a {
        font-size: 22px;
    }
}

@media screen and (max-width: 400px) {
    .popup-content-small .email a {
        font-size: 17px;
    }
}